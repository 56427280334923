import { Injectable, Input } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { candidateProfile } from './../../../core/models/candidate.model';
import { APIResponse } from './../../../core/models/response.model';
import { HttpParams } from '@angular/common/http';
import { AdminClient } from './../../../core/models/admin-client.model';
import { UserTypeService, UserType } from '../services/user-type.service';
import { CookieService } from 'ngx-cookie-service';
import { UnpTransferee } from '../components/unprocessed-list/unprocessed-list.component';
import { ComplianceListModel} from './../../../core/models/compliance-list.model';

const ComplianceList = 'v1/admin/candidate/compliance'
export interface locationFilter {
  countries: Array<string>;
  states: Array<string>;
  cities: Array<string>;
}

export interface CandidateFilter {
  filters: {
    departure: locationFilter,
    destination: locationFilter
  };
  searchText: string;
}

export interface CalenderInvite {
  options: {
      smart_invite_id: string,
      callback_url: string,
      event: {
          summary: string,
          description: string,
          start: Date,
          end: Date,
          tzid: string,
          location: {
              description: string
          }
      }
  };
  orderId: string;
  action?: string;
}
export interface PetsInformation {
  name: string,
  type: string,
  breed: string,
  weight: number
}
export interface AssessNeeds {
  accompanyingMembers: Array<any>,
  petsInformation: PetsInformation[],
  departureLocation: string,
  travelHistory: Array<string>,
  assessNeedsDraft: boolean
  assessNeedsSubmittedDate: any
}
export interface HousingPreference {
  desiredLocation?: {
    city: string,
    state: string,
    country: string
  },
  alternateLocation?: {
    city: string,
    state: string,
    country: string
  },
  destinationOfficeLocation?: {
    city: string,
    state: string,
    country: string
  },
  moveInDate?: string,
  moveOutDate?: string,
  numberBedrooms?: string,
  numberApartments?: any,
  babyCotRequired?: boolean,
  acceptableCommute?: number,
  priorities?: Array<string>,
  additionalInstructions?: string,
  housingPreferencesDraft?: boolean,
  housingPreferencesSubmittedDate?: string,
  numberBedroomsValueList?: Array<string>,
  prioritiesValueList?: Array<string>,
  accommodationType?: any,
  housekeepingInfo?: {
    housekeepingFrequency?: string,
    comments?: any;
  }
  furnished?: boolean,
  kitchenRequired?: boolean,
  parkingInfo?: {
    parkingApproved?: boolean,
    financialResponsibility?: string,
    comments?: any;
  }
  numberOfBathroomsValueList?: any,
  numberOfBathrooms?: string,
}
export interface CustomerRelocationDetails {
  filePriority?: any,
  daysAuthorized?:any,
  serviceDeliveryLocation?: any,
  financialResponsibility?: any,
  clientBudgetCurrency?: any,
  clientDirectedProviderComment?: any,
  provider?: any,
  requiresResidencyLetter?: any,
  clientBudgetAmount?: any
}

export interface bookingDetails{
  bookingAgent?: any,
  statusUpdatedDate?: any,
  status?: any,
  providerName?: any,
  propertyName?: any,
  apartmentType?:any,
  rentPaidBy?: any,
  moveDates?: {
    moveIn?: any,
    moveOut?: any
  },
  address?: {
    streetLine1?: any,
    city?: any,
    state?: any,
    country?: any,
    postalCode?: any
  }

}

export interface BookingAgent {
  partyId?:string;
}

export interface GetUnpTransferee {
  sortBy ?: string;
  sortDirection ?: string;
  searchText?: string;
  filters ?: any;
  itemsPerPage?: string;
  pageNumber?:string;
}

export interface client {
  clientId: string;
  clientNumber: string;
  clientEntityName: string;
}

export interface clientList {
  clients: client []
}

@Injectable({
  providedIn: 'root'
})
export class CandidateProfileService {
 /**
   * base constructor
   * @param baseClientService baseclient service
   * @param logSvc remote logging service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private userTypeService: UserTypeService,
    private cookieService: CookieService
    // private readonly customLogger: LoggerService
  ) { }

   /**observable for subject */
   selectedValue$ = new Subject<string>();

   refreshListSubject = new Subject<any>();
   public refreshListSubject$: Observable<any> = this.refreshListSubject.asObservable();
   newCandidate: candidateProfile;

  /** user role */
  userRole: UserType = this.userTypeService.userType;

  /** clientID of the selected client */
  clientId: any;
  public searchText = new BehaviorSubject <any>(null);

  /**subject submission failure  */
  submissionFailureListSubject = new BehaviorSubject(null);
  public submissionFailureListSubject$: Observable<any> = this.submissionFailureListSubject.asObservable();

  // changes start  error review data passing by  behavioral subject
  UnprocessReferenceData = new BehaviorSubject <any>(null);
  public UnprocessReferenceData$: Observable<any> = this.UnprocessReferenceData.asObservable();

  /** BehaviorSubject to store uploaded document */
  uloadedDocument = new BehaviorSubject<any>(null);

    // changes end
   /**
    * Returns the list of table filters available for Candidate List
    */
   getListFilters(): Observable<{ moveStatuses: Array<string> }> {
      return this.baseClientService.getOne<any>('v1/admin/candidate/filters').pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to get Locations: ', err);
          return of(empty);
        })
      );
   }

   /**
    * Return the candidate json list and loop to display in the table
    * @param queryString querystring values
    * @param clientDetails client Id to be passed for Program/account manager
    */
   getCandidateProfiles(options: any, clientDetails: AdminClient, moveOrcostproj: string): Observable<{ candidates: Array<candidateProfile>, count: number }> {

    let httpParams = new HttpParams();
    if (options && options.hasOwnProperty('itemsPerPage') && options.hasOwnProperty('pageNumber')) {
      httpParams = httpParams.set('itemsPerPage', options.itemsPerPage);
      httpParams = httpParams.set('pageNumber', options.pageNumber+1);
    }
    if (options && options.sortBy && options.sortDirection) {
      httpParams = httpParams.set('sortBy', options.sortBy);
      httpParams = httpParams.set('sortDirection', options.sortDirection);
    }
    let data;
    data = (options && options.searchText) ? { searchText: options.searchText, searchProperties: options.searchProperties } : {};
    clientDetails ? data['clientId'] = clientDetails.clientId : null;
    data['type'] = moveOrcostproj;
    if (this.userRole !== 'client-contact-initiator') {
    this.clientId = clientDetails.clientId; // assigning for use later. temp fix until the listing of transferees against that division
    }
    return this.baseClientService
      .post<any>(`${ (options && options.hasOwnProperty('itemsPerPage') && options.hasOwnProperty('pageNumber')) ?
        'v1/admin/candidate/list?' + httpParams :
        'v1/admin/candidate/list'}`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const emptyArray = null;
          return of(emptyArray);
        })
      );
   }

   searchCandidateProfiles(searchBody: any, clientDetails: AdminClient, moveOrcostproj: string):
     Observable<{ candidates: Array<candidateProfile>, count: number }> {
     let httpParams = new HttpParams();
     if (searchBody && searchBody.hasOwnProperty('itemsPerPage') && searchBody.hasOwnProperty('pageNumber')) {
       httpParams = httpParams.set('itemsPerPage', searchBody.itemsPerPage);
       httpParams = httpParams.set('pageNumber', searchBody.pageNumber+1);
     }
     if (searchBody && searchBody.hasOwnProperty('sortBy') && searchBody.hasOwnProperty('sortDirection')) {
       httpParams = httpParams.set('sortBy', searchBody.sortBy);
       httpParams = httpParams.set('sortDirection', searchBody.sortDirection);
       delete searchBody.sortBy;
       delete searchBody.sortDirection;
     }
     delete searchBody.itemsPerPage;
     delete searchBody.pageNumber;
    clientDetails ? searchBody['clientId'] = clientDetails.clientId : null;
    searchBody['type'] = moveOrcostproj;
    return this.baseClientService
      .post<any>(`${ 'v1/admin/candidate/list?' + httpParams}`, searchBody || {})
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const emptyArray = null;
          return of(emptyArray);
        })
      );
   }

   /**
    * use to subscribe to selectedValue$
    */
   getMessage(): Observable<any> {
     return this.selectedValue$.asObservable();
   }
   /**
    * used to add a candidate
    * @param request candidate whose details are to be added
    */
   createCandidate(request: candidateProfile){
     return this.baseClientService.post<candidateProfile>('v1/admin/candidate/', request).pipe(
       map(r => r.body),
       catchError((err, source) => {
         let empty: any = null;
        if(err.message.includes('SSO')) {
          const errSplit = err.message.split(':');
          empty = errSplit[1];
        }
         this.logSvc.logError('Failed to add transferee details' + err);
       console.log('Failed to add candidate profiles' + err);
         return of(empty);
       })
     );
   }

    /**
    * used to add a candidate
    * @param request candidate whose details are to be added
    */
   updateCandidate(request: candidateProfile){
     if(request.moveStatus && request.moveStatus == 'Invitation Sent' || request.moveStatus == 'Authorization Initiated'){
      delete request.optionalDetails;
      delete request.departureAddr;
      delete request.destinationAddr;
      delete request.nameDetails;
      delete request.programDetails;
      delete request.moveStatusDate;
      delete request.moveStatus;
      delete request.divisionPartyId;
      delete request.assignedPolicy;
      delete request.executiveOfficer;
      if(request.authorizationType==="Traditional") {
        delete request.executiveOfficer;
        delete request.assignedPolicy;
        delete request.executiveOfficer;
        delete request.moveType;
        delete request.deliveryTeamModel;
        delete request.initialContactBy;
        delete request?.isLumpSum;
      }
      if(request.authorizationDetails){
        delete request.authorizationDetails;
      }
      delete request.authorizationDate;
      delete request.authorizationTimezone;
      delete request.cliAuthSubDt;
      delete request.cliAuthSubTime;
      delete request.authReadytoProcessDt;
      delete request.authReadytoProcessTime;
      delete request.authReadytoProcessTimezone;
      delete request.cliAuthSubTimezone;
     }

    return this.baseClientService.put<candidateProfile>('v1/admin/candidate/', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        let empty: any = null;
        if(err.message.includes('SSO')) {
          const errSplit = err.message.split(':');
          empty = errSplit[1];
        }
        this.logSvc.logError('Failed to add transferee details' + err);
      console.log('Failed to update candidate profiles' + err);
        return of(empty);
      })
    );
  }

  updateCandidateLocation(request: candidateProfile,reqId){
   return this.baseClientService.put<candidateProfile>(`v1/admin/candidate/updateLocationInfo/${reqId}`, request).pipe(
     map(r => r.body),
     catchError((err, source) => {
       const empty: candidateProfile = null;
       this.logSvc.logError('Failed to add transferee details' + err);
     console.log('Failed to update candidate profiles' + err);
       return of(empty);
     })
   );
 }

   /**
    * used to send email invite to candidate
    * @param request candidate to whom invite is to be sent
    */
   sendInviteToCandidate(request: candidateProfile): Observable<any> {
     return this.baseClientService
       .post<APIResponse>(`v1/admin/candidate/authorized`, request)
       .pipe(
         map(r => r.body),
         catchError((err, source) => {
          this.logSvc.logError('Failed to send invite' + err);
          // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
          let empty: string = null;
          if(err.message.includes('SSO')) {
            const errSplit = err.message.split(':');
            empty = errSplit[1];
            return of(empty);
          }
          return of(null);
         })
       );
   }

   /**
    * used to send email invite to candidate
    * @param request candidate to whom invite is to be sent
    */
   reAuthorizeToCandidate(request: candidateProfile): Observable<any> {
    return this.baseClientService
      .put(`v1/admin/candidate/authorized`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to resend invite' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
         let empty: string = null;
          if(err.message.includes('SSO')) {
            const errSplit = err.message.split(':');
            empty = errSplit[1];
            return of(empty);
          }
         return null;
        })
      );
   }

    /**
    * used to send email invite to candidate
    * @param request candidate to whom invite is to be sent
    */
   reSendInviteToCandidate(request: candidateProfile): Observable<any> {
    delete request.optionalDetails;
    delete request.departureAddr;
    delete request.destinationAddr;
    delete request.nameDetails;
    delete request.programDetails;
    delete request.moveStatusDate;
    delete request.moveStatus;
    delete request.divisionPartyId;
    delete request.assignedPolicy;
    if(request.authorizationDetails && this.userRole !== 'consultant'){
      delete request.authorizationDetails;
    }
    return this.baseClientService
      .put<APIResponse>(`v1/admin/candidate/resend-invite`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          let empty: any = null;
          if(err.message.includes('SSO')) {
            const errSplit = err.message.split(':');
            empty = errSplit[1];
            return of(empty);
          }
         this.logSvc.logError('Failed to resend invite' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
         return of(null);
        })
      );
  }

  moveNotApprove(orderid: string): Observable<any> {
    const body =  {
      status:'Move Not Approved'
    };
      return this.baseClientService
      .put<any>(`v1/admin/candidate/order-request/${orderid}/move-notapproved`,body)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to move not approved' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
         return of(null);
        })
      );
  }

  approveMove(orderReqId: string,candidate: any): Observable<any> {
      //return of({});
      return this.baseClientService
      .put<any>(`v1/admin/candidate/order-request/${orderReqId}/authorize-cpmove`,candidate)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          let empty: any = null;
          if(err.message.includes('SSO')) {
            const errSplit = err.message.split(':');
            empty = errSplit[1];
            return of(empty);
          }
         this.logSvc.logError('Failed to move not approved' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
         return null;
        })
      );
  }

  /**
   * Search Candidate Distinct Locations
   * @param type Type of search destination or departure
   * @param searchText Actual Search text
   */
  searchCandidateLocations(type: string, searchText: string): Observable<{ countries: Array<string>,
    states: Array<string>, cities: Array<string> }> {
   //  this.userRole = this.userTypeService.userType;
    let baseURL = `v1/admin/candidate/locations?type=${type}&searchText=${searchText}`;

    if (!type || !searchText) { return; }
    if (this.userRole !== 'client-contact-initiator') {
      baseURL = baseURL + `&clientId=${this.clientId}`
    }
      return this.baseClientService.getOne<any>(baseURL).pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to get Locations: ', err);
          return of(empty);
        })
      );
  }

    /**
    * used to deactivate transferee
    * @param request array of order request id's to be deactivated
    */
   deactivateTransferee(request): Observable<any> {
    return this.baseClientService
      .put<APIResponse>(`v1/admin/candidate/deactivate`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to deactivate transferee' + err);
         return of(null);
        })
      );
  }

  /**
   * get division
   */
  getDivision(divisionId) {
    let httpParams: HttpParams = new HttpParams();
    if (divisionId) {
      httpParams = httpParams.append('divisionId', divisionId.toString());
    }
    return this.baseClientService.getArr<any>(`v1/admin/cc-division?${httpParams.toString()}`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get Division: ', err);
        return of(empty);
      })
    );
  }

  /** get search candidate for duplicate check  */
  getCandidateCheck(reqObj) {
    let httpParams: HttpParams = new HttpParams();
    return this.baseClientService.post<any>(`v1/admin/candidate/checkCandidates`, reqObj).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get cnadidate for duplicate check: ', err);
        return of(empty);
      })
    );
  }

   /**
   * get program on the basis of division
   */
  getProgram(divisionId, type) {
    return this.baseClientService.getArr<any>(`v1/admin/cc-division/programs/${divisionId}?type=${type}`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get Program: ', err);
        return of(empty);
      })
    );
  }

     /**
   * get amount and currency for the lumsum program
   */
     getLumpSumAmountAndCurrency(programId) {
      return this.baseClientService.getArr<any>(`v1/admin/program/${programId}/traditional`).pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to get Lump Sum Program: ', err);
          return of(empty);
        })
      );
    }

  /**
   * get transferee order request
   */
  getTransferee(reqId) {
    return this.baseClientService.getOne<any>(`v1/admin/candidate/order-request/${reqId}`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get transferee request id: ', err);
        return of(empty);
      })
    );
  }


     /**
   * Method to add AssessNeeds Items
   * @param assessNeeds
   */
      addAssessNeedsItem(assessNeeds: AssessNeeds, housingPreference :HousingPreference,customerRelocationDetails:CustomerRelocationDetails,bookingAgent:BookingAgent, submit:boolean, ID): Observable<any> {
        let reqbody= {needsAssessment:assessNeeds,housingPreference:housingPreference,customerRelocationDetails:customerRelocationDetails,bookingAgent:bookingAgent, submit}
      // addAssessNeedsItem(assessNeeds: AssessNeeds, ID): Observable<any> {
      //   let reqbody= {needsAssessment:assessNeeds}
      return this.baseClientService
          .put(`v1/admin/candidate/supplier-order-request/${ID}`, reqbody)
          .pipe(
            map(r => r.body),
            catchError((err, source) => {
              const empty = null;
              return of(err);
            })
          );
      }

      /**
   * Method to add AssessNeeds Items
   * @param bookingDetails
   */
    getWorkorderDetails(reqId,orderid) {
      let orderId= !orderid;
    return this.baseClientService.getOne<any>(`v1/admin/candidate/supplier-order-request/${reqId}?orderFlag=${orderId}`).pipe(
      map(r => r.body), catchError((err, source) =>{
        const empty: any = null;
        console.log('failed to get details: ',err);
        return of(empty);

      })
    );
  }

  deleteWorkOrder(reqId,orderid) {
    let orderId= !orderid;
    return this.baseClientService.delete<any>(`v1/admin/candidate/supplier-order-request/${reqId}?orderFlag=${orderId}`).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to unconfirm benefit' + err);
        console.log('Failed to unconfirm benefit' + err);
        return of(empty);
      })
    );
  }
  /**
   * Method to add orderRequest
   * @param orderRequest
   */
addOrderRequest(assessNeeds: AssessNeeds, housingPreference :HousingPreference,customerRelocationDetails:CustomerRelocationDetails,bookingAgent:BookingAgent, submit:boolean, ID): Observable<any>{
  let reqbody= {needsAssessment:assessNeeds,housingPreference:housingPreference,customerRelocationDetails:customerRelocationDetails,bookingAgent:bookingAgent, submit}
  return this.baseClientService.post<any>(`v1/admin/candidate/supplier-order-request/${ID}`,reqbody).pipe(
    map(r => r.body),
    catchError((err, source) => {
      const empty: any = null;
      return of(empty);
    })
  );
}

/**
   * Method to accept order
   * @param acceptOrder
   */
acceptOrder(id,modifyID) : Observable<any>{
  return this.baseClientService.put<any>(`v1/admin/candidate/order/${id}/modification/${modifyID}/accept`, {}).pipe(
    map(r => r.body),
    catchError((err, source) => {
      const empty: any = null;
      return of(empty);
    })
  );
}



/**
   * Method to reject Order
   * @param rejectOrder
   */

 rejectOrder(id) : Observable<any>{
  return this.baseClientService.put<any>(`v1/admin/candidate/order/${id}/modification/reject`, {}).pipe(
    map(r => r.body),
    catchError((err, source) => {
      const empty: any = null;
      return of(empty);
    })
  );
}


     /**
   * Method to get bookingDetails Items
   * @param bookingDetails
   */
  getBookingDetails(id){
    return this.baseClientService.getOne<any>(`v1/admin/candidate/order/${id}/booking-details`).pipe(
      map(r=> r.body), catchError((err,source)=>{
        const empty:any = null;
        console.log('failed to get details: ',err);
        return of(empty);
      })
    )
  }


 /**
   * Method to update bookingDetails Items
   * @param updatebookingDetails
   */

updateBookingDetails(bookingOrderData:bookingDetails,id):Observable<any>{
  return this.baseClientService
  .put(`v1/admin/candidate/order/${id}/booking-details`,bookingOrderData)
  .pipe(map(r=>r.body),
  catchError((err, source)=>{
    const empty = null;
    return of(empty);
  })
  );
}

  getSharedSessionCode(orderId: string, name: string): Observable<{ session: string }> {
    return this.baseClientService.post<any>(`v1/admin/candidate/co-browsing/session`, { orderId, name }).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to generate session code : ', err);
        return of(empty);
      })
    );
  }

  /** Mark policy complete of order request Id */
  makePolicyCallComplete(reqId: string, obj: any) {
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${reqId}/policyCallStatus`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to add transferee details' + err);
      console.log('Failed to update candidate profiles' + err);
        return of(empty);
      })
    );
  }

   /**
    * used to update points of candidate
    * @param request order request id whose points are to be updated
    */
   updatePoints(request, obj) {
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${request}`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: candidateProfile = null;
        this.logSvc.logError('Failed to update program details' + err);
      console.log('Failed to update program details' + err);
        return of(empty);
      })
    );
  }
  /**
  * used to update amount of candidate
  * @param request order request id whose amount are to be updated
  */
  updateAmount(request, obj, param) {
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${request}`, obj, '', param).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: candidateProfile = null;
        this.logSvc.logError('Failed to update program details' + err);
      console.log('Failed to update program details' + err);
        return of(empty);
      })
    );
  }


  /**
    * used to update job start date of candidate
    * @param requestId order request id whose Job start Date are to be updated
    * @param obj object required to pass for updating job start date
    */
   updateJobStartDate(requestId, obj) {
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${requestId}/jobstartdate`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to update Job start date' + err);
      console.log('Failed to update Job start date' + err);
        return of(empty);
      })
    );
  }

  unconfirmBenefits(orderId: string) {
    let url = `v1/admin/candidate/order-request/${orderId}/unconfirmBenefit`;
    return this.baseClientService.put<string>(url, {}).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to unconfirm benefits' + err);
        console.log('Failed to unconfirm benefits' + err);
        return of(empty);
      })
    );
  }

  unconfirmSelectedBenefit(orderId: string, productId = null) {
    let url = `v1/admin/candidate/order-request/${orderId}/unconfirmBenefit`;
    url += productId ? `/${productId}` :  ``;
    return this.baseClientService.delete<string>(url).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to unconfirm benefit' + err);
        console.log('Failed to unconfirm benefit' + err);
        return of(empty);
      })
    );
  }

  updateProgramDetails(requestId, obj) {
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${requestId}/changeProgramName`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to update program Name' + err);
      console.log('Failed to update program Name' + err);
        return of(empty);
      })
    );
  }
  updateContractDetails(requestId, obj) {
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${requestId}/contract-details`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to update program Name' + err);
      console.log('Failed to update program Name' + err);
        return of(empty);
      })
    );
  }

  /**
    * used to update points of candidate
    * @param productId order request id whose points are to be updated
    */
   unlockSubmittedHighValueGoods(productId) {
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/benefit/${productId}/hvg/unlock`, {}).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: candidateProfile = null;
        this.logSvc.logError('Failed to unlock high value goods' + err);
        console.log('Failed to unlock high value goods' + err);
        return of(empty);
      })
    );
  }

  /**
    * used to update
    * @param orderId
    */
   updateCustomFields(orderId, data: any):Observable<any> {
    console.log(orderId);
   // /v1/admin/candidate/order-request/:orderReqId/additional-details
  return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${orderId}/additional-details`, data).pipe(
    map(r => r.body),
    catchError(err => {
      this.logSvc.logError(err);
      const emptyArray = null;
        return of(emptyArray);
      })
  );
}
  /**
    * used to reschedule policy Call of candidate
    * @param reqObj object required to pass for rescheduling policy call date
    */
  reSchedulePolicyCall(reqObj: any) {
    const calendarInvite: CalenderInvite = {
      options: {
          callback_url: 'https://example.yourapp.com/cronofy/smart_invite/notifications',
          event: {
              description: 'Benefits Builder - Policy call',
              summary: 'Benefits Builder - Policy call',
              tzid: reqObj.tzid,
              start: reqObj.start,
              end: reqObj.end,
              location: {
                  description: ''
              }
          },
          smart_invite_id: reqObj.smart_invite_id
      },
      action: 'reSchedule',
      orderId: reqObj.orderId
    };

    let httpParams = new HttpParams({
      fromObject:
        { "transferee-context": reqObj.partyId }
    });

    return this.baseClientService
      .post('v1/cronofy/calendarInvite', calendarInvite, '',httpParams,true)
            .pipe(
                map(r => r.body),
                catchError(err => {
                    console.log('Failed to Reschedule Policy Call', err);
                    const emptyResp = null;
                    return of(emptyResp);
                })
            );
  }

  /** Send Invitation email for initial contact call complete of order request Id */
  sendInvitationInitialCallComplete(reqObj: any) {
    return this.baseClientService
    .post<APIResponse>(`v1/admin/notify/transferee/invite`, reqObj)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to send invite' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
         let empty: string = null;
         if(err.message.includes('SSO')) {
           const errSplit = err.message.split(':');
           empty = errSplit[1];
           return of(empty);
         }
         return null;
        })
      );
  }
  /** for traditional transferee get employee type, InitialContactBy values, TraditionalAuthTimeZone values */
  getTradOptionalValues(query): Observable<any> {
    return this.baseClientService
    .getOne<any>(`v1/admin/value-list?key=${query}`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }

  /**get Transferee move order details */
  getMoveDetails(candidatePartyId, clientId) {
    const payload = {
      candidatePartyId: candidatePartyId,
      clientId: clientId,
      type: 'move'
    }
    return this.baseClientService.post<any>(`v1/admin/candidate/list`,payload).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get transferee move details: ', err);
        return of(empty);
      })
    );
  }

  /**fsu - traditional contract list */
  getContractList(clientId) {
    return this.baseClientService
      .getOne<any>(`v1/admin/contract-list?clientId=${clientId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getContractListBB(programId) {
    return this.baseClientService
      .getOne<any>(`v1/admin/contract-list?context=benefits-builder&programId=${programId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getCurrencies(): Observable<any> {
    return this.baseClientService
    .getOne<any>(`v1/admin/value-list?key=Currencies`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }

  getOrderPriority(): Observable<any> {
    return this.baseClientService
    .getOne<any>(`v1/admin/value-list?key=OrderPriority`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }


  /**to update sso value by fsu */
  updateSSOValue(orderId, ssoValue): Observable<any> {
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${orderId}/sso-identifier`, { ssoIdentifier: ssoValue }).pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        let empty = null;
        if(err.message.includes('SSO')) {
          const errSplit = err.message.split(':');
          empty = errSplit[1];
        }
        return of(empty);
      })
    )
  }

  /**
  * Method to get actual expenses
  */
  getActualExpenses(): Observable<any> {
    return this.baseClientService
      .getArr<any>(`v1/benefit/actual-expenses`, null, null, true)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          console.log('Failed to get actual expenses: ', err);
          return of({isError: true, error: err});
        })
      );
  }


/**
 * This method is used to resend invite
 * @param requestBody
 * @returns
 */
  resendInviteCall(requestBody): Observable<any> {
    return this.baseClientService.put<any>(`v1/admin/candidate/email`,requestBody).pipe(
      map(response => {
        return response;
      }),
      catchError((err, source) => {
        this.logSvc.logError('Failed to send invite' + err);
        let empty: string = null;
        if(err.message.includes('SSO')) {
          const errSplit = err.message.split(':');
          empty = errSplit[1];
          return of(empty);
        }
        return null;
       })
    )
  }



  /** To Resend Email*/
  resendSendInvitation(reqObj: any) {
    return this.baseClientService
    .put<APIResponse>(`v1/admin/candidate/intial-call-complete-resend-invite`, reqObj)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to send invite' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
         let empty: string = null;
         if(err.message.includes('SSO')) {
           const errSplit = err.message.split(':');
           empty = errSplit[1];
           return of(empty);
         }
         return null;
        })
      );
  }
  getListofPointRecalculation(reqID) {
    return this.baseClientService
    .getOne<any>(`v1/admin/consultant/reCalculate?orderId=${reqID}`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError("Recalculate points request failed since prior and new value are same"+err);
        const empty = null;
        return of(empty);
      })
    );
  }

  updatePointsRecalculation(value) {
    return this.baseClientService
    .put<APIResponse>(`v1/admin/consultant/reCalculate`,value)
    .pipe(
      map(r => r.body),
      catchError((err, source) => {
       this.logSvc.logError('Failed to resend invite' + err);
       // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
       return of(null);
      })
    );
  }

  getPointsRecalculationHistory(reqID) {
    return this.baseClientService
      .getOne<any>(`v1/admin/consultant/reCalcHistory?orderRequestId=${reqID}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError("Recalculate points request failed since prior and new value are same" + err);
          const empty = null;
          return of(empty);
        })
      );
  }
    /** Mark initial contact call complete of order request Id */
    makeInitialContactCallComplete(reqId: string) {
      return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${reqId}/initialCallStatus`,{}).pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.logSvc.logError('Failed to add transferee details' + err);
        console.log('Failed to update candidate profiles' + err);
          return of(empty);
        })
      );
    }
    
    getDmsData(id) {
      return this.baseClientService.getOne<any>(`v1/admin/document-management/?orderRequestId=${id}`).pipe(
        map(r=> r.body), catchError((err,source)=>{
          const empty:any = null;
          console.log('failed to get details: ',err);
          return of(empty);
        })
      )
    }

    /** DMS download file related function */
    getDMSDetails(orderRequestId, dmsData){
      const filetype = dmsData.docType;
      const docId = dmsData.docId;
      let baseURL = `v1/admin/document-management/guid?docType=${filetype}&docId=${docId}&orderRequestId=${orderRequestId}`;
      return this.baseClientService.getOne<any>(baseURL).pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to get DMS file: ', err);
          return of(empty);
        })
      );
    }
  
    /** DMS upload file related function */
    postDMSDetails(orderRequestId, docDetails) {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.append('orderRequestId', orderRequestId);
      httpParams = httpParams.append('calledFrom', 'UI');
      let baseURL = `v1/admin/document-management/guid?` + httpParams.toString();
      return this.baseClientService.post<any>(baseURL, docDetails).pipe(
        map(r => r.body), catchError((err, source) => {
          const empty: any = null;
          console.log('Failed to get DMS file: ', err);
          return of(empty);
        })
      );
    }

    getUnprocessedTransferee(options: GetUnpTransferee = null): Observable<{ getUnprocessData: Array<any>, count: number }> {
      let httpParams: HttpParams = new HttpParams();
      if (options && options.hasOwnProperty('itemsPerPage') && options.hasOwnProperty('pageNumber')) {
        httpParams = httpParams.append('itemsPerPage', options.itemsPerPage);
        httpParams = httpParams.append('pageNumber', options.pageNumber+1);
      }
      if (options) {
        if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
        if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      }

      let data: GetUnpTransferee = {};
      options && options.searchText ? data.searchText = options.searchText : null;
      options && options.filters ? data.filters = options.filters : null;
      return this.baseClientService
      .post<any>(`${ options || 
                     (options.hasOwnProperty('sortBy') && 
                     options.hasOwnProperty('sortDirection')) || 
                     (options.hasOwnProperty('itemsPerPage') && 
                     options.hasOwnProperty('pageNumber')) ?
                     'v1/admin/candidate/unprocessed?' + httpParams.toString() : 
                     'v1/admin/candidate/unprocessed' }`,data)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to fetch transferee' + err);
         return null;
        })
      );
    }

    /** Compliance List service integration */
  getComplianceList(options): Observable<ComplianceListModel[]> {
    let requestPayload = {}
    let httpParams: HttpParams = new HttpParams()
    if (options) {
      if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
      if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      if (options.hasOwnProperty('itemsPerPage')) { httpParams = httpParams.append('itemsPerPage', options.itemsPerPage); }
      if (options.hasOwnProperty('pageNumber')) { httpParams = httpParams.append('pageNumber', options.pageNumber + 1); }
    }
    if (options.searchText) {
      requestPayload['searchText'] = options.searchText
    }
    if (options.filters) {
      requestPayload['filters'] = options.filters
    }

    return this.baseClientService
      .post<any>(`${ComplianceList}?` + httpParams.toString(), requestPayload)
      .pipe(
        map((r) => {
          if (r) {
            const complianceData = r.body?.candidates.map((c) => {
              const compliantData = new ComplianceListModel(c)
              return compliantData
            })
            if (complianceData) {
              complianceData.count = r.body?.count || 0
            }
            return complianceData
          }
        }),
        catchError((err, source) => {
          this.logSvc.logError('Failed to fetch transferee' + err);
          return null;
        })
      );
  }

  adjudicateCompliantTransferee(orderRequestID, requestBody) {
    return this.baseClientService
    .post<any>(`${ComplianceList}/${orderRequestID}`, requestBody)
    .pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to fetch transferee' + err);
        return of(null);
      })
    );
  }

  getUnprocessedClientList(): Observable<client[]> {
    return this.baseClientService
    .getOne<any>(`v1/admin/candidate/unprocessed/client`)
    .pipe(
      map(r => r.body.clients),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }

  getComplianceClientList(): Observable<client[]> {
    return this.baseClientService
    .getOne<any>(`v1/admin/candidate/compliance/clients`)
    .pipe(
      map(r => r.body.clients),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }

  // changes start
  // https://integrationtst01.cartus.com/v1/admin/candidate/unprocessed/{_id} method DELETE.
  getDeleteRecord(_id) {
    return this.baseClientService
    .delete<any>(`v1/admin/candidate/unprocessed/${_id}`)
    .pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to fetch transferee' + err);
        return of(null);
      })
    );
  }
  // changes end
    
   InitiateUnprocessedTransferee(request: candidateProfile): Observable<any> {
    return this.baseClientService
      .post<APIResponse>(`v1/admin/candidate/unprocessed/authorize`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
         this.logSvc.logError('Failed to send invite' + err);
         // return this.catchBlock('sending Invite to Candidate Failed', err, 'string'); 
         let empty: string = null;
         if(err.message.includes('SSO')) {
           const errSplit = err.message.split(':');
           empty = errSplit[1];
          }
          return of(empty);
        })
      );
  }
  
  /** DMS download file related function */
  getDMSDowloadFile(dmsDetails){
    const guid = sessionStorage.getItem('car-ses-guid');
    const docId = dmsDetails.queryParams.docId;
    let baseURL = `v2.0/onbase/hsdocument?guid=${guid}&docId=${docId}`;
    return this.baseClientService.getDMS(baseURL).pipe(
      map(r => r), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get DMS download file: ', err);
        return of(empty);
      })
    );
  }

  getPolicyHistory(orderId, status) {
    return this.baseClientService
    .getOne<any>(`v1/admin/audit-histories/policyCallStatus?orderId=${orderId}&history=${status}`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }
  
  /** 
   * used to Activate Deactivated Transferee
   * @param reqId - orderRequestId */
  activateTransferee(reqId: string) {
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${reqId}/activate`,{}).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to activate the transferee' + err);
        console.log('Failed to restore the transferee' + err);
        return of(empty);
      })
    );
  }

  /** DMS download file related function */
  uploadDMSFile(formData){
    let baseURL = `v2.0/onbase/hsdocument`;
    return this.baseClientService.dmsPost(baseURL, formData).pipe(
      map(r => r), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get DMS download file: ', err);
        return of(empty);
      })
    );
  }
  
  /**
    * used to schedule policy Call of candidate
    * @param reqObj object required to pass for scheduling policy call date
    */
  schedulePolicyCall(reqObj) {
    const phoneNumber = reqObj.candidateDetails.phoneDetailsList.find(phone => phone.primary);
    const calendarInvite: CalenderInvite = {
        options: {
            callback_url: 'https://example.yourapp.com/cronofy/smart_invite/notifications',
            event: {
                description: 'Benefits Builder - Policy call',
                summary: 'Benefits Builder - Policy call',
                tzid: reqObj.tzid,
                start: reqObj.start,
                end: reqObj.end,
                location: {
                    description: `By Phone ${phoneNumber}`
                }
            },
            smart_invite_id: `MY_POLICY_CALL_${Date.now()}`
        },
        action: 'schedule',
        orderId: reqObj.orderId
    };
  
    let httpParams = new HttpParams({
      fromObject:
        { "transferee-context": reqObj.partyId }
    });


    return this.baseClientService
        .post('v1/cronofy/calendarInvite', calendarInvite,'', httpParams,true)
        .pipe(
            map(r => r.body),
            catchError(err => {
                console.log('Failed to send invite', err);
                const emptyResp = null;
                return of(emptyResp);
            })
        );
  }
   /** To fetch client specific Auth router box email address*/
   getClientRouterdetails(clientId) {
    return this.baseClientService
      .getOne<any>(`v1/admin/client/${clientId}/router-detail`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError("Failed to fetch router details" + err);
          return of();
        }));
  }

  /** To update client specific Auth router box email address*/
  updateClientRouterDetails(clientId: string, requestBody) {
    return this.baseClientService.put<any>(`v1/admin/client/${clientId}/router-detail`, requestBody).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to update client email addrress' + err);
        return of();
      }));
  }

  /** To update restricted locations*/
  updateLocation(orderReqId: string, requestBody) {
    return this.baseClientService.put<any>(`v1/admin/candidate/compliance/${orderReqId}`, requestBody).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to update location' + err);
        return of();
      }));
  }

  markAuditCallComplete(reqId: string) {
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${reqId}/auditStatus`,{}).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to get trasfree audit status details' + err);
      console.log('Failed to get trasfree audit status details' + err);
        return of(empty);
      })
    );
  }

  changeEligibilityDate(orderReqId: string,requestBody){
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${orderReqId}/lumpsum-payment-date`, requestBody).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to update eligibility date' + err);
        return of();
      }));
  }

  updateLumpSumAmountData(orderReqId: string,requestBody){
    return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${orderReqId}/lumpsum-amount`, requestBody).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to update eligibility date' + err);
        return of();
      }));

  }

  updateRepaymentDocMilestone(requestBody){
    requestBody.Detail.appContext = sessionStorage.getItem('car-ses-con')
    return this.baseClientService.post<any>(`v1/admin/event`, requestBody).pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError('Failed to update milestone for repayment aggrement document' + err);
        return of();
      }));
  }

  updateEstimatedArrivalDate(requestId, obj) {
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${requestId}/estimated-arrival-date`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to update estimate arrival date' + err);
        console.log('Failed to update estimate arrival date' + err);
        return of(empty);
      })
    );
  }

  getBankInfoData(orderRequestId){
    return this.baseClientService
    .getArr<any>(`v1/order-request/${orderRequestId}/bank`).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        return of(empty);
      })
    );
  }
  
  deleteDocument(docId,docType,orderRequestID){
    return this.baseClientService.delete<any>(`v1/admin/document-management/?docType=${docType}&docId=${docId}&orderRequestId=${orderRequestID}`).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to delete document' + err);
        console.log('Failed to delete document' + err);
        return of(empty);
      })
    );
  }

  updateAssingedPolicy(requestId,assignedPolicy){
    let obj = {"assignedPolicy":assignedPolicy}
    return this.baseClientService.put<string>(`v1/admin/candidate/order-request/${requestId}/policy`, obj).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to update assigned policy' + err);
        console.log('Failed to update assigned policy' + err);
        return of(empty);
      })
    );
  }
}


